import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { DxMenuModule } from 'devextreme-angular/ui/menu';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { LanguagePipe } from '../pipes/language.pipe';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { TableContainerComponent } from '../components/table-container/table-container.component';
import { DxResponsiveBoxModule } from 'devextreme-angular/ui/responsive-box';
import { DxBoxModule } from 'devextreme-angular/ui/box';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';

import { ImprintComponent } from '../components/imprint/imprint.component';
import { PrivacyComponent } from '../components/privacy/privacy.component';
import { ContactComponent } from '../components/contact/contact.component';
import { CardComponent } from '../components/card/card.component';
import { EinrichtungDetailsComponent } from '../components/einrichtung-details/einrichtung-details.component';
import { PageContainerComponent } from '../components/page-container/page-container.component';
import { RemoveAccountComponent } from '../components/remove-account/remove-account.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { CalculatorComponent } from '../components/calculator/calculator.component';
import { DxGalleryModule } from 'devextreme-angular/ui/gallery';
import { DxAutocompleteModule } from 'devextreme-angular/ui/autocomplete';
import { DxSortableModule } from 'devextreme-angular/ui/sortable';
import { DxSliderModule } from 'devextreme-angular/ui/slider';
import { DxFilterBuilderModule } from 'devextreme-angular/ui/filter-builder';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';

import { AutocompleteboxOsmComponent } from '../components/autocompletebox-osm/autocompletebox-osm.component';
import { MapOsmComponent } from '../components/map-osm/map-osm.component';
import { DxSpeedDialActionModule } from 'devextreme-angular/ui/speed-dial-action';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { AddInstitutionComponent } from '../components/add-institution/add-institution.component';

// import { DxChartModule } from 'devextreme-angular/ui/chart';
// import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
// import { DxRangeSelectorModule } from 'devextreme-angular/ui/range-selector';
// import { DxPopoverModule } from 'devextreme-angular/ui/popover'
// import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
// import { DxMenuModule } from 'devextreme-angular/ui/menu';

@NgModule({
  declarations: [
    LanguagePipe,
    EinrichtungDetailsComponent,
    ImprintComponent,
    PrivacyComponent,
    ContactComponent,
    CardComponent,
    TableContainerComponent,
    PageContainerComponent,
    RemoveAccountComponent,
    ChangePasswordComponent,
    CalculatorComponent,
    AutocompleteboxOsmComponent,
    MapOsmComponent,
    AddInstitutionComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DxMenuModule,
    DxDrawerModule,
    DxListModule,
    DxFormModule,
    DxButtonModule,
    DxToolbarModule,
    DxDataGridModule,
    DxTemplateModule,
    DxNumberBoxModule,
    DxLoadIndicatorModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxTabPanelModule,
    DxPopupModule,
    DxScrollViewModule,
    DxFileUploaderModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxAccordionModule,
    DxResponsiveBoxModule,
    DxBoxModule,
    DxTagBoxModule,
    DxGalleryModule,
    DxSliderModule,
    DxAutocompleteModule,
    DxFilterBuilderModule,
    DxSortableModule,
    DxRadioGroupModule,
    DxSpeedDialActionModule,
    DxPopoverModule,
    DxLoadPanelModule,

    // DxLoadIndicatorModule,
    // DxScrollViewModule,
    // DxChartModule,
    // DxPieChartModule,
    // DxRangeSelectorModule,
    // DxPopoverModule,
    // DxAccordionModule,
    // DxDateBoxModule,
    // DxMenuModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LanguagePipe,
    EinrichtungDetailsComponent,
    ImprintComponent,
    PrivacyComponent,
    ContactComponent,
    CardComponent,
    TableContainerComponent,
    PageContainerComponent,
    RemoveAccountComponent,
    ChangePasswordComponent,
    AutocompleteboxOsmComponent,
    AddInstitutionComponent,
    MapOsmComponent,
    DxMenuModule,
    DxDrawerModule,
    DxListModule,
    DxFormModule,
    DxButtonModule,
    DxToolbarModule,
    DxDataGridModule,
    DxTemplateModule,
    DxNumberBoxModule,
    DxLoadIndicatorModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxTabPanelModule,
    DxPopupModule,
    DxScrollViewModule,
    DxFileUploaderModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxAccordionModule,
    DxResponsiveBoxModule,
    DxBoxModule,
    DxTagBoxModule,
    DxGalleryModule,
    DxSliderModule,
    DxAutocompleteModule,
    DxFilterBuilderModule,
    DxSortableModule,
    DxRadioGroupModule,
    DxSpeedDialActionModule,
    DxPopoverModule,
    DxLoadPanelModule,

    // DxLoadIndicatorModule,
    // DxScrollViewModule,
    // DxChartModule,
    // DxPieChartModule,
    // DxRangeSelectorModule,
    // DxAccordionModule,
    // DxDateBoxModule,
    // DxMenuModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InjectorModule {
  static forRoot(): ModuleWithProviders<InjectorModule> {
    return {
      ngModule: InjectorModule,
      providers: [],
    };
  }
}
