<div class="app-content" *ngIf="!isInit">
  <span>{{ 'initializeApplication' | translate }}</span>
</div>

<div class="app-content" *ngIf="isInit">
  <router-outlet></router-outlet>
</div>

<app-einrichtung-details *ngIf="isInit"></app-einrichtung-details>
<app-remove-account *ngIf="isInit"></app-remove-account>
<app-change-password *ngIf="isInit"></app-change-password>
<app-error-handler-popup *ngIf="isInit"></app-error-handler-popup>
