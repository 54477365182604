<div class="card">
    <!-- <p class="card-header" [innerHtml]='headerText | translate'></p> -->
    <p class="card-header" [innerHtml]='headerText'></p>
    <p class="card-content" [innerHtml]='bodyText'></p>
    <div class="card-button-container">
        <dx-button class="card-btn" [ngClass]="{
                'card-btn-left' : buttonAlignmentRight===false,
                'card-btn-right' : buttonAlignmentRight===true
            }"
            type="default" icon={{buttonIcon}} [text]='buttonText' [disabled]="canBeDisabled && selectedIds.length < 1" (onClick)="onClick()">
        </dx-button>
    </div>
</div>

