import { Injectable } from '@angular/core';

@Injectable()
export class MobileService {

    isMobile:boolean = false;
    constructor() {
        this.isMobile = this._isMobile();
    }

    private _isMobile(): boolean {
        let _hasTouchScreen: boolean = false;


        const _navigator: any = window.navigator;


        if ('maxTouchPoints' in _navigator) {
            _hasTouchScreen = _navigator.maxTouchPoints > 0;
        } else if ('msMaxTouchPoints' in _navigator) {
            _hasTouchScreen = _navigator.msMaxTouchPoints > 0;
        } else {
            const mQ = window.matchMedia('(pointer:coarse)');


            if (mQ && mQ.media === '(pointer:coarse)') {
                _hasTouchScreen = !!mQ.matches;
            } else if ('orientation' in window) {
                _hasTouchScreen = true;
            } else {
                const UA = navigator.userAgent;
                _hasTouchScreen = (
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
                );
            }
        }


        return _hasTouchScreen;
    }
}