import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { RouteKeys } from '../helper/route-keys.helper';
import { ApiService } from './api.service';
import { LanguageService } from './language.service';

@Injectable()
export class ConfigService {
  public rollen: any[] = [
    {
      id: 1,
      bezeichnung: 'Administrator',
    },
    {
      id: 2,
      bezeichnung: 'Leitung',
    },
    {
      id: 3,
      bezeichnung: 'Sachbearbeitung 1',
    },
    {
      id: 4,
      bezeichnung: 'Sachbearbeitung 2',
    },
    {
      id: 5,
      bezeichnung: 'Azubi',
    },
    {
      id: 6,
      bezeichnung: 'Trägerverwaltung',
    },
    {
      id: 7,
      bezeichnung: 'Einrichtungsverwaltung',
    },
  ];

  public wochentage: any[] = [
    {
      key: 1,
      value: 'monday',
    },
    {
      key: 2,
      value: 'tuesday',
    },
    {
      key: 3,
      value: 'wednesday',
    },
    {
      key: 4,
      value: 'thursday',
    },
    {
      key: 5,
      value: 'friday',
    },
  ];

  public antragsStatus: any[] = [
    {
      key: 0,
      value: 'open',
    },
    {
      key: 1,
      value: 'inProcess',
    },
    {
      key: 2,
      value: 'inWaiting',
    },
    {
      key: 3,
      value: 'proposed',
    },
    {
      key: 4,
      value: 'accepted',
    },
    {
      key: 5,
      value: 'declined',
    },
  ];

  public emailPattern: string = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';
  public telefonPattern: string = '/^[0123456789/()+- ]+$/';

  private allKataloge: any[] = [];
  public kataloge: any[] = [];
  public katalogeMitKeineAntragstellung: any[] = [];
  public strassen: any[] = [];
  public traeger: any[] = [];
  public einrichtungen: any[] = [];
  public globalSettings: any[] = [];
  public mandantName: string;

  isInit: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedLanguageObservable: Subscription;

  constructor(private languageService: LanguageService, private apiService: ApiService) {}

  init(): Observable<boolean> {
    this.apiService.post<any>(RouteKeys.Config.get_config, null).subscribe((response) => {
      this.allKataloge = response.kataloge;
      this.strassen = response.strassen;
      this.traeger = response.traeger;
      this.einrichtungen = response.einrichtungen;
      this.globalSettings = response.globalSettings;

      this.mandantName = response.mandantName;
      this.selectedLanguageObservable = this.languageService.get_selectedLanguageObservable.subscribe((lang) => {
        this.buildPatternMessages();
        this.getPasswordPolicy();
      });
      this.isInit.next(true);
    });

    return this.isInit.asObservable();
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageObservable) {
      this.selectedLanguageObservable.unsubscribe();
    }
  }

  public getStrassen() {
    return this.strassen;
  }

  public getTraeger() {
    return this.traeger;
  }

  noConfigFoundWarning: string[] = [];
  public getKatalog(typ: string) {
    const exist = this.kataloge[typ];
    if (exist !== null && exist !== undefined && exist.length > 0) {
      return exist;
    } else {
      const newKatalog = this.allKataloge.filter((x) => x.typ === typ && x.keineAntragstellung !== true);
      if (newKatalog !== null && newKatalog !== undefined && newKatalog.length > 0) {
        this.kataloge[typ] = newKatalog;
        return newKatalog;
      } else {
        if (!this.noConfigFoundWarning.find((x) => x === typ)) {
          this.noConfigFoundWarning.push(typ);
          console.warn('Katalog ' + typ + ' nicht gefunden!');
        }
      }
    }
  }

  public getKatalogMitKeineAntragstellung(typ: string) {
    const exist = this.katalogeMitKeineAntragstellung[typ];
    if (exist !== null && exist !== undefined && exist.length > 0) {
      return exist;
    } else {
      const newKatalog = this.allKataloge.filter((x) => x.typ === typ);
      if (newKatalog !== null && newKatalog !== undefined && newKatalog.length > 0) {
        this.katalogeMitKeineAntragstellung[typ] = newKatalog;
        return newKatalog;
      } else {
        if (!this.noConfigFoundWarning.find((x) => x === typ)) {
          this.noConfigFoundWarning.push(typ);
          console.warn('Katalog ' + typ + ' nicht gefunden!');
        }
      }
    }
  }

  public getMandantName(): string {
    return this.mandantName;
  }

  public getGlobalSettngsWithKategorie(kategorie: string) {
    const result = [];
    for (let index = 0; index < this.globalSettings.length; index++) {
      const element = this.globalSettings[index];
      if (element.kategorie !== undefined && element.kategorie !== null && element.kategorie.length > 0) {
        const exist = element.kategorie.find((x) => x === kategorie);
        if (exist !== undefined && exist !== null && exist.length > 0) {
          result.push(element);
        }
      }
    }
    return result;
  }

  public getGlobalSetting(param: string): string {
    if (param !== '' && param !== undefined) {
      const result = this.globalSettings.find((x) => x.key === param);
      if (result != null && result !== undefined) {
        return result.value;
      }
    }
    return '';
  }

  public getGlobalSettingAsNumber(param: string): number {
    const result = this.getGlobalSetting(param).replace(',', '.');
    if (!isNaN(Number(result))) {
      return Number(result);
    }
    return 0;
  }

  public getGlobalSettingAsDate(param: string): Date {
    const result = this.stringToDate(this.getGlobalSetting(param));
    return result;
  }

  public getMaxSelectedEinrichtungen(): number {
    return 0;
  }

  public getPlzPattern() {
    return /^[0123456789]+$/;
  }

  public passwordPolicy: any;

  buildPatternMessages() {
    this.passwordPolicy = {
      text: '',
      minZiffern: '',
      minZiffernMessage: this.languageService.translate('missingNumbers'),
      minKleinBuchstaben: '',
      minKleinBuchstabenMessage: this.languageService.translate('missingLowLetters'),
      minGrossBuchstaben: '',
      minGrossBuchstabenMessage: this.languageService.translate('missingUpLetters'),
      minSonderzeichen: '',
      minSonderzeichenMessage: this.languageService.translate('missingSpecials'),
      minLaenge: '',
      minLaengeMessage: this.languageService.translate('shortPassword'),
      maxLaenge: '',
      maxLaengeMessage: this.languageService.translate('longPassword'),
    };
  }

  getPasswordPolicy() {
    const policy = this.getGlobalSetting('passwordPolicy');

    if (policy != null && policy != undefined && policy.length > 0) {
      const pswPatternArray = policy.split(',').map((n) => {
        return Number(n);
      });

      if (pswPatternArray[0] > 0) {
        this.passwordPolicy.minLaenge = '[A-Za-z\\d$@$!%*?&#_-]{' + pswPatternArray[0].toString() + ',}';
        this.passwordPolicy.text =
          '<p>' +
          this.languageService.translate('patternArray0_1') +
          pswPatternArray[0] +
          (pswPatternArray[5] >= pswPatternArray[0] ? this.languageService.translate('patternArray0_2') + pswPatternArray[5] : '') +
          this.languageService.translate('patternArray0_3');
      }
      if (pswPatternArray[1] > 0) {
        this.passwordPolicy.minZiffern = '(?=([^0-9]*[0-9]){' + pswPatternArray[1].toString() + ',})';
        this.passwordPolicy.text =
          this.passwordPolicy.text.trim() +
          '<br>' +
          this.languageService.translate('patternArray1_1') +
          pswPatternArray[1] +
          this.languageService.translate('patternArray1_2');
      }
      if (pswPatternArray[2] > 0) {
        this.passwordPolicy.minKleinBuchstaben = '(?=([^a-z]*[a-z]){' + pswPatternArray[2].toString() + ',})';
        this.passwordPolicy.text =
          this.passwordPolicy.text.trim() +
          '<br>' +
          this.languageService.translate('patternArray2_1') +
          pswPatternArray[2] +
          this.languageService.translate('patternArray2_2');
      }
      if (pswPatternArray[3] > 0) {
        this.passwordPolicy.minGrossBuchstaben = '(?=([^A-Z]*[A-Z]){' + pswPatternArray[3].toString() + ',})';
        this.passwordPolicy.text =
          this.passwordPolicy.text.trim() +
          '<br>' +
          this.languageService.translate('patternArray3_1') +
          pswPatternArray[3] +
          this.languageService.translate('patternArray3_2');
      }
      if (pswPatternArray[4] > 0) {
        this.passwordPolicy.minSonderzeichen = '(?=(.*[$@$!%*?&#_-]){' + pswPatternArray[4].toString() + ',})';
        this.passwordPolicy.text =
          this.passwordPolicy.text.trim() +
          '<br>' +
          this.languageService.translate('patternArray4_1') +
          pswPatternArray[4] +
          this.languageService.translate('patternArray4_2');
      }
      if (pswPatternArray[5] > 0) {
        this.passwordPolicy.maxLaenge = pswPatternArray[5];
        this.passwordPolicy.text =
          this.passwordPolicy.text.trim() +
          (pswPatternArray[0] == 0
            ? '<br>' + this.languageService.translate('patternArray5_1') + pswPatternArray[5] + this.languageService.translate('patternArray5_2')
            : '');
      }

      this.passwordPolicy.text =
        this.passwordPolicy.text.trim() != '' ? '<b>' + this.languageService.translate('passwordPolicy') + ':</b>' + this.passwordPolicy.text : '';
    } else {
      console.warn('Password-Policy nicht gefunden!');
    }
  }

  private stringToDate(str: string): Date {
    // Datumformat muss zwingend dd.mm.yyyy sein!
    if (str.length > 9 && str.length < 20) {
      const [dateValues, timeValues] = str.split(' ');
      const [day, month, year] = dateValues.split('.');
      const [hours, minutes, seconds] = timeValues.split(':');
      const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
      return date;
    }
    return null;
  }

  public getMonthName(monthNumber, lang) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString(lang, { month: 'long' });
  }
}
