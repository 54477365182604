<dx-box class="box" direction="row" width="100%">
    <dxi-item class="box-rand" [baseSize] = "750" [shrink] = "10">
        <span></span>
    </dxi-item>
    <dxi-item class="box-center" [baseSize] = 'initSize' [shrink] = "1">
        <div class="box-content" id="page-container-box-content">
                <ng-content></ng-content>
        </div>
    </dxi-item>
    <dxi-item class="box-rand" [baseSize] = "750" [shrink] = "10">
        <span></span>
    </dxi-item>
</dx-box>        
