<dx-popup #errorHandlerPopup title='{{"errorPopupHeader" | translate}}' class="error-popup" [fullScreen]="false"
    [showTitle]="true" [hideOnOutsideClick]="false" [showCloseButton]="false" [width]="800" [minWidth]="800"
    [height]="530" [minHeight]="530" [resizeEnabled]="true" [dragEnabled]="true" (onInitialized)="onInitialized($event)">
    <div *dxTemplate="let data of 'content'">
        <div class="error-popup-content">
            <span class="data-text">{{'errorPopupDisclamer' | translate}}</span>
            <span class="data-text-detail">- URL</span>
            <span class="data-text-detail">{{'errorBrowserDisclaimer' | translate }}</span>
            <span class="data-text-detail">{{'errorUserIdDisclaimer' | translate }}</span>
            <span class="data-text-detail">{{'errorInformationDisclaimer' | translate }}</span>
            <span class="data-text-detail">{{'errorDescriptionDisclaimer' | translate }}</span>
            <span class="data-text">{{'errorDescriptionLabel' | translate }}</span>
            <dx-text-area class="description-area" [height]="100"
                placeholder='{{"errorDescriptionPlaceholder" | translate}}' maxLength="1000" [(value)]="description">
            </dx-text-area>
        </div>
    </div>

    <dxi-toolbar-item template="customTitleTemplate" location="before" toolbar="bottom">
        <div *dxTemplate="let data of 'customTitleTemplate'">
            <span class="data-text">{{'thxForHelp' | translate }}</span>
        </div>
    </dxi-toolbar-item>

    <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom" [options]="{
        stylingMode: 'contained',
        text: ('submit' | translate),
        type: 'default',
        icon: 'check',
        onClick: onClickSend
    }">
    </dxi-toolbar-item>

    <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom" [options]="{
        stylingMode: 'contained',
        text: ('cancel' | translate),
        type: 'default',
        icon: 'remove',
        onClick: onClickCancel
    }">
    </dxi-toolbar-item>
</dx-popup>