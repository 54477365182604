<app-page-container>
    <div *ngIf="isFaqOn > 0" class="faq-container">
      <div class="faq-Header">
        <span>{{'faq' | translate}}</span>
      </div>
      <dx-scroll-view class="faq-scrollable-contant" [showScrollbar]="scrollbarMode">
        <dx-accordion class="faq-Accordion" #accordion [dataSource]="dataSource" [collapsible]="true" [multiple]="false"
          [animationDuration]="300" [selectedIndex]="-1">
          <div *dxTemplate="let question of 'title'">
            {{question.frage}}
          </div>
          <div *dxTemplate="let question of 'item'">
            <div class="faq-answer-text">
              <p [innerHTML]="question.antwort">
              </p>
            </div>
          </div>
        </dx-accordion>
      </dx-scroll-view>
    </div>

    <div class="contact-container">
      <div class="contact-Header">
        <span>{{'contact' | translate}}</span>
      </div>

      <dx-form class="contact-Form" #contactForm [minColWidth]="350" [formData]="contactFormData" [colCount]="2">

        <dxi-item itemType="group" [colCount]="1">
          <dxi-item dataField="name" [label]="{ text: 'yourName' | translate}"
            [editorOptions]="{ tabIndex: 1, maxLength: 50  }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="email" [label]="{ text: 'yourMail' | translate }"
            [editorOptions]="{ tabIndex: 2, maxLength: 50  }">
            <dxi-validation-rule type="email" message="{{ 'wrongMail' | translate }}"></dxi-validation-rule>
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="configService.emailPattern"
              message="{{ 'wrongMail' | translate }}"></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="titel" [label]="{ text: 'subject' | translate }"
            [editorOptions]="{ tabIndex: 3, maxLength: 50  }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="1">

          <dxi-item class="contactForm-messagebox" dataField="nachricht" [label]="{ text: 'message' | translate }"
            editorType="dxTextArea" [editorOptions]="{ tabIndex: 4, height: 206 }" cssClass="bigger-textArea">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
        </dxi-item>


          <dxi-item cssClass= "datenschutz-container" dataField="confirm" [label]="{ visible: false }" [template]="'confirmTemplate'">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
    
          <div *dxTemplate="let data of 'confirmTemplate'">
            <dx-check-box [(value)]="confirm">
            </dx-check-box>
            <span class = "datenschutz-link">
              {{'privacyAccept_1' | translate}}<a target='_blank' href='#/extern/privacy'> {{'privacyAccept_2' | translate}}</a>{{'privacyAccept_3' | translate}} *
            </span>
          </div>

      </dx-form>
      <dx-toolbar class="contact-Toolbar">
        <dxi-item widget="dxButton" location="before" toolbar="bottom" [disabled]="loading"
          [options]="{ text: 'clear' | translate, onClick: onClickReset, type:'default', icon: 'revert', stylingMode: 'filled' }">
        </dxi-item>

        <dxi-item widget="dxButton" location="after" toolbar="bottom" [disabled]="!confirm || loading"
          [options]="{ text: 'submit' | translate, onClick: onClickSend, type:'default', icon: 'message', stylingMode: 'filled' }">
        </dxi-item>

        <!-- versteckte Felder -->
        <!-- <dxi-item 
              cssClass="betreff" dataField="betreff" [label]="{ text: 'contact_subject' | translate }"
              [editorOptions]="{ tabIndex: 3, maxLength: 50, focusStateEnabled: false }">
          </dxi-item>

          <dxi-item dataField="confirm" [visible] = 'false' [label]="{ visible: false }">
          </dxi-item> -->
      </dx-toolbar>
      <div class="msg-container">
        <span *ngIf="success === true" class="msg-text color-success">{{msg}}</span>
        <span *ngIf="success === false" class="msg-text color-error">{{msg}}</span>
      </div>
    </div>

    <div class="download_link" *ngIf="linkToDownload !== undefined && linkToDownload !== null && linkToDownload.length > 0">
      <span>{{'linkToDownload' | translate}}</span>
      <span><a href={{linkToDownload}} target="_blank"></a></span>
    </div>

    <dx-load-panel
      #loadPanel
      shadingColor="rgba(0,0,0,0.4)"
      [position]="{ of: '#contactForm' }"
      [(visible)]="loading"
      [showIndicator]="true"
      [showPane]="true"
      [shading]="true"
      [hideOnOutsideClick]="false"
      message="Daten werden aktualisiert..."
    >
    </dx-load-panel>    
</app-page-container>