<dx-popup
  #addInstitutionPopup
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [width]="700"
  [height]="250"
  (onHidden)="onHidden($event)"
  [wrapperAttr]="{ class: 'popup-wrapper'}"
>
  <dxi-toolbar-item template="customTitleTemplate" location="before">
    <div *dxTemplate="let data of 'customTitleTemplate'" class="popup-header">
      <div class="popup-header-title">{{ 'selectInstitution' | translate }}</div>
    </div>
  </dxi-toolbar-item>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [disabled]="formData.id===null || formData.id===undefined"
    [options]="{
      stylingMode: 'contained',
      text: ('accept' | translate),
      type: 'default',
      icon: 'check',
      onClick: onSelectClick
    }"
  >
  </dxi-toolbar-item>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{
      stylingMode: 'contained',
      text: ('close' | translate),
      type: 'default',
      icon: 'remove',
      onClick: onCloseClick
    }"
  >
  </dxi-toolbar-item>

  <div class="popup-container-content">
    <dx-form [formData]="formData" #form>
      <dxi-item
        dataField="id"
        editorType="dxSelectBox"
        [label]="{ text: ' ' }"
        [editorOptions]="{
          dataSource: institutionList,
          valueExpr: 'id',
          displayExpr: 'adresse',
          searchExpr: 'adresse',
        }"
      >
      </dxi-item>
    </dx-form>
  </div>
</dx-popup>
