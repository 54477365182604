<app-page-container>

  <div class="calc-container-content prime-fg-color-3">
    <div class="calcHeader">
      <span><b>{{'calculator' | translate}}</b></span>
    </div>
    
    <dx-form class="calcForm" #calcForm [minColWidth]="320" [formData]="formData">
      <dxi-item>
        <div class="msg-text" [innerHtml]="hinweisText"></div>
      </dxi-item>
      
      <dxi-item itemType="group" colCount="2">
        <dxi-item dataField="betreuungsArt" editorType="dxSelectBox"
          [label]="{ text: 'careType' | translate }" [colSpan]="1"
          [editorOptions]="{ tabIndex: 1, dataSource: betreuungsArtDataSource, valueExpr: 'id', displayExpr: 'bezeichnung', searchEnabled: true, 
          searchMode: 'startswith', searchTimeout: 200, searchExpr: 'bezeichnung', onValueChanged: onBetreuungsArtValueChanged }">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="betreuungsUmfang" editorType="dxSelectBox" [label]="{ text: 'careTime' | translate }"
          [colSpan]="1"
          [editorOptions]="{ tabIndex: 2, dataSource: betreuungsUmfangDataSource, valueExpr: 'id', displayExpr: 'bezeichnung', searchEnabled: true, searchMode: 'startswith', searchTimeout: 200, searchExpr: 'bezeichnung' }">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" colCount="2">
        <dxi-item *ngIf="istErmaessigungsTypRelevant"  dataField="anzahlKinder" [label]="{ text: (istZaehl_KZ ? 'positionOfKid' : 'numberKids') | translate }" editorType="dxNumberBox"
          [editorOptions]="{ valueChangeEvent:'keyup', min: 1, max: 10, format: (istZaehl_KZ ? kind_format : ''), onChange: onCalcClick }">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="einkommen" [label]="{ text: einkommenText }" [visible]="istEKAbhaengig" editorType="dxNumberBox"
          [editorOptions]="{ valueChangeEvent:'keyup', format: '#,##0.00 €', min: 0, max: 999999, disabled: formData.istMaxEinkommen, 
              showClearButton: true, onChange: onCalcClick, value: null, step: 100}">
            <dxi-validation-rule *ngIf="!formData.istMaxEinkommen" type="required"></dxi-validation-rule>
        </dxi-item>

      </dxi-item>
      <dxi-item itemType="group" colCount="2">
        <dxi-item *ngIf="istErmaessigungsTypRelevant" itemType="empty"></dxi-item>
        <dxi-item dataField="istMaxEinkommen" editorType="dxCheckBox" [label]="{text: ' '}" [visible]="istEKAbhaengig"
          [editorOptions]="{ cssClass: 'checkbox-text', valueChangeEvent: 'keyup', text: 'calcMaxFee' | translate, onValueChanged: onMaxEkValueChanged }">
        </dxi-item>        
      </dxi-item>              

        <!-- <dxi-item itemType="empty"></dxi-item> -->
        <!-- <dxi-item dataField="alleinerziehend" editorType="dxCheckBox" [label]="{text: ' '}"
          [editorOptions]="{ cssClass: 'checkbox-text', valueChangeEvent: 'keyup', text: 'singleParent' | translate}">
        </dxi-item> -->      

    </dx-form>

    <div *ngIf="istBerechnet && betrag >= 0" class="result-container result-ok">
      <span>{{'calcOkMessage' | translate }} {{betrag | currency:'EUR': true}}.</span>
    </div>

    <div *ngIf="istBerechnet && betrag !== -1" class="result-container result-add">
      <span>{{'calcAddMessage' | translate }}</span>
    </div>
    
    <div *ngIf="istBerechnet && betrag === -1" class="result-container result-fail">
      <span>{{'calcFailMessage_1' | translate }} <a href='#/extern/contact'>{{'calcFailMessage_2' | translate}}</a> {{'calcFailMessage_3' | translate }}</span>
    </div>

    <span class="msg-text color-error">{{msg}}</span>
    
    <dx-toolbar class="calcToolbar">
      <dxi-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'calculate' | translate, onClick: onCalcClick, type:'default', icon: 'check', stylingMode: 'filled', disabled: inProcess }">
      </dxi-item>
      <dxi-item widget="dxButton" location="before" toolbar="bottom"
        [options]="{ text: 'clear' | translate, onClick: onClickReset, type:'default', icon: 'revert', stylingMode: 'filled' }">
      </dxi-item>      
    </dx-toolbar>
  </div>
</app-page-container>