<dx-popup #removeAccountPopup [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="true" title="{{ 'deleteAccount' | translate}}" width="auto" minWidth="350" maxWidth="650" height="auto">

    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view #scrollView>
            <div class="scrolledContent">
                <div class="privacy_text" [innerHtml] = privacy_delete></div>
            </div>
        </dx-scroll-view>

        <dx-form #deleteForm class="delete-account-edit-form" [width]="'100%'" [formData]="deleteFormData">
            <dxi-item itemType="empty"></dxi-item>
            <dxi-item dataField="checkDelete" editorType="dxCheckBox" [label]="{ visible:false}" [editorOptions]="{ text: 'readRegulations' | translate}" >
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="passwort" [editorOptions]="{mode:'password'}"
            [label]="{ text: 'password' | translate}">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
        </dx-form>            
        <div class="msg-text-container">
            <span [class]='isRemoved ? "msg-text color-success" : "msg-text color-error"'>{{msg}}</span>
        </div>        
    </div>

    <dxi-toolbar-item widget="dxButton" location="before" toolbar="bottom"
    [options]="{ text: 'cancel' | translate, type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose, disabled: isRemoved }">
    </dxi-toolbar-item>

    <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom"
    [options]="{ text: 'deleteAccount' | translate, type: 'default', stylingMode: 'contained',  icon: 'trash', onClick: onClickDelete, disabled: isRemoved }">
    </dxi-toolbar-item>
</dx-popup>
