export namespace RouteKeys {
    export namespace Auth {
        export const get_token: string = 'Auth/GetToken';
    }

    export namespace Config {
        export const get_config: string = 'Config/GetConfig';
        export const get_version: string = 'Config/GetVersion';
    }

    export namespace Extern {
        export const get_search: string = 'ExternSearch/Search';
        export const get_selected: string = 'ExternSelected/GetSelected';
        export const get_global_settings: string = 'ExternGlobalSettings/GetValue';
        
        export namespace Register {
            export const register_account: string = 'ExternBenutzer/RegisterAccount';
            export const send_password_link: string = 'ExternBenutzer/SendPasswordLink';
            export const verify_email: string = 'ExternBenutzer/VerifyEmail';
            export const send_verify_email: string = 'ExternBenutzer/SendVerifyEmail';
            export const reset_password: string = "ExternBenutzer/ResetPassword"
        }
    }

    export namespace Faq {
        export const get_question: string = 'Faq/Search';
    }

    export namespace Contact {
        export const send_message: string = 'Mail/SendContactMail';
    }

    export namespace Intern {
        export namespace Buerger {
            export const set_inquire: string = 'InternBuergerInquire/Create';
            export const get_all_inquire: string = 'InternBuergerInquire/GetAll';
            export const get_inquire: string = 'InternBuergerInquire/Get';
            export const delete_inquire: string = 'InternBuergerInquire/RemoveInquire';
            export const update_profile: string = 'BenutzerBuerger/UpdateProfile';
            export const update_password: string = 'BenutzerBuerger/UpdatePassword';
            export const remove_account: string = 'BenutzerBuerger/Remove';
            export const get_profile: string = 'BenutzerBuerger/GetProfile';
            export const create_pdf: string = 'InternBuergerInquire/CreateAntragPdf';
            export const check_duplicate: string = 'InternBuergerInquire/CheckDuplicate';
            export const save_document: string = 'Dokumente/Save';
            export const get_doctext: string = 'Dokumente/GetDoctext';
            export const send_inquireEmail: string = 'InternBuergerInquire/SendInquireMail';
            export const update_inquire: string = 'InternBuergerInquire/Update';
            export const accept_suggestion: string = 'InternBuergerInquire/AcceptSuggestion';
            export const decline_suggestion: string = 'InternBuergerInquire/DeclineSuggestion';
            export const get_all_documents: string = 'Dokumente/GetAll';
            export const delecte_document: string = 'Dokumente/Remove';
            export const check_status: string = 'InternBuergerInquire/CheckStatus';
        }

        export namespace Department {
            export const get_search: string = 'InternDepartmentInquirelist/Search';
        }
    }

    export namespace Imprint {
        export const get_imprint: string = 'ExternImprint/Get';
    }
    
    export namespace Privacy {
        export const get_privacy: string = 'ExternPrivacy/Get';
    }

    export namespace Vorlage {
        export const create: string = 'Vorlagen/Create';
    }

    export namespace Calculator {
        export const get_beitrag: string = 'Beitrag/GetBeitrag';
    }

    export namespace Exception {
        export const log_error: string ='Exception/LogError';
        export const send_mail: string = 'Exception/SendMail';
    }
}