import { Component, OnDestroy} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnDestroy {

  privacyText: SafeHtml;
  selectedLanguageObservable: Subscription;
  
  constructor(private apiService: ApiService, private languageService: LanguageService, private sanitizer: DomSanitizer) {

    this.selectedLanguageObservable = languageService.get_selectedLanguageObservable.subscribe((lang) => {
      lang = lang || 'de';
      var langToSend = lang === 'de' ? 'privacyDE' : lang === 'en' ? 'privacyEN' : 'privacyDE';

      this.apiService.post(RouteKeys.Privacy.get_privacy, { key: langToSend }).subscribe((response: any) => {
        this.privacyText = this.sanitizer.bypassSecurityTrustHtml(response.wert);
      }, err => {
        throw err?.error?.message || err?.error?.Message || this.languageService.translate('serverError');
      });
    });
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageObservable) {
      this.selectedLanguageObservable.unsubscribe();
    }
  }
}
