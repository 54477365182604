import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';


@Component({
  selector: 'app-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss']
})
export class TableContainerComponent implements OnInit {
  @ViewChild('tableContainer', { static: false }) tableContainer: TableContainerComponent;

  @Input () public headerText: string = '';
  @Input () public btnExportVisible: boolean = true;
  @Input () public btnPrintVisible: boolean = true;
      
  @Output () onBtnExportClick = new EventEmitter<boolean>();
  @Output () onBtnPrintClick = new EventEmitter<boolean>();

  
  constructor() { }

  onExportClick() {
    this.onBtnExportClick.emit();
  }

  onPrintClick() {
    this.onBtnPrintClick.emit();
  }

  ngOnInit(): void {}

}
