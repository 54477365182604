import { Component, OnInit, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { PopupService } from '../../services/popup.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-error-handler-popup',
  templateUrl: './error-handler-popup.component.html',
  styleUrls: ['./error-handler-popup.component.scss'],
})
export class ErrorHandlerPopupComponent implements OnInit {
  @ViewChild('errorHandlerPopup', { static: false }) errorHandlerPopup: DxPopupComponent;

  description: string = '';
  error: any;

  // subscription_get_PopupAsObservable: Subscription;
  constructor(private popupService: PopupService, private route: ActivatedRoute, private apiService: ApiService) {
    this.onClickSend = this.onClickSend.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);

    // this.subscription_get_PopupAsObservable = this.popupService.get__errorHandlerPopupAsObservable.subscribe(context => {
    //   if (context !== null && context !== undefined && context.open === true && context.error !== null && context.error !== undefined) {
    //     this.error = context.error;
    //     this.error.browser = this.detectBrowserName() + " " + this.detectBrowserVersion();
    //     this.error.url = location.href;
    //     this.errorHandlerPopup.instance.show();
    //   }
    // });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    // if (this.subscription_get_PopupAsObservable) {
    //   this.subscription_get_PopupAsObservable.unsubscribe();
    // }
  }

  onInitialized(e) {
    e.component.registerKeyHandler('tab', (arg) => {
      arg.stopPropagation();
    });
  }

  onClickSend(e) {
    this.error.description = this.description;

    this.logError(() => {
      this.sendMail(() => {
        this.errorHandlerPopup.instance.hide().then(() => {
          this.error = null;
          this.description = null;
        });
      });
    });
  }

  onClickCancel(e) {
    this.error.description = null;
    this.logError(() => {
      this.errorHandlerPopup.instance.hide().then(() => {
        this.error = null;
        this.description = null;
      });
    });
  }

  logError(cb: () => void) {
    this.apiService.post(RouteKeys.Exception.log_error, this.error).subscribe(() => {
      if (cb) {
        cb();
      }
    });
  }

  sendMail(cb: () => void) {
    this.apiService.post(RouteKeys.Exception.send_mail, this.error).subscribe(() => {
      if (cb) {
        cb();
      }
    });
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  detectBrowserVersion() {
    const userAgent = navigator.userAgent;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let tem;
    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edg)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
      matchTest.splice(1, 1, tem[1]);
    }
    return matchTest.join(' ');
  }
}
