<div class="content-container">
    <div class="content-container-head">
        <div class="content-container-head-text">
            <h6>{{headerText}}</h6>
        </div>        
        <div class="content-container-head-buttons">
            <dx-button *ngIf="btnExportVisible" class="tc-button"
                type="default" icon='export' hint="{{'export' | translate}}" (onClick)="onExportClick()">
            </dx-button>
            <dx-button *ngIf="btnPrintVisible" class="tc-button" 
                type="default" icon='print' hint="{{'print' | translate}}" (onClick)="onPrintClick()">
            </dx-button>
        </div>        
    </div>        
    <div class="content-left-container-table">
        <!-- Hier kommt die Tabelle rein -->
        <ng-content></ng-content>
    </div>        
</div>        