import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DxBoxComponent } from 'devextreme-angular/ui/box';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent implements OnInit {

  @Input () public initSize: number = 1700;

  @ViewChild('pageContainer', { static: false }) pageContainer: DxBoxComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
