import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PopupService {
  constructor() {}

  // Einrichtung-Detail-Popup
  private _detailPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_detailPopupAsObservable(): Observable<any> {
    return this._detailPopup.asObservable();
  }

  showDetailPopup(item, preview: boolean = false) {
    this._detailPopup.next({
      open: true,
      item: item,
      preview: preview,
    });
  }

  // Einrichtung-Hinzufügen-Popup
  private _addInstitutionPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_addInstitutionPopupAsObservable(): Observable<any> {
    return this._addInstitutionPopup.asObservable();
  }

  showAddInstitutionPopup() {
    this._addInstitutionPopup.next({
      open: true,
    });
  }

  // Benutzerkonto-Löschen-Popup
  private _removeAccountPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_removeAccountPopupAsObservable(): Observable<any> {
    return this._removeAccountPopup.asObservable();
  }

  showRemoveAccountPopup() {
    this._removeAccountPopup.next({
      open: true,
    });
  }

  // Passwort-Ändern-Popup
  private _changePasswordPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_changePasswordPopupAsObservable(): Observable<any> {
    return this._changePasswordPopup.asObservable();
  }

  showChangePasswordPopup() {
    this._changePasswordPopup.next({
      open: true,
    });
  }
}
