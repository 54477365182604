import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements  OnDestroy {

  imprintText: SafeHtml;
  selectedLanguageObservable: Subscription;

  constructor(private apiService: ApiService, private languageService: LanguageService, private sanitizer: DomSanitizer) {
    this.selectedLanguageObservable = languageService.get_selectedLanguageObservable.subscribe((lang) => {
      lang = lang || 'de';
      var langToSend = lang === 'de' ? 'impressumDE' : lang === 'en' ? 'impressumEN' : 'impressumDE';

      this.apiService.post(RouteKeys.Imprint.get_imprint, { key: langToSend }).subscribe((response: any) => {
        this.imprintText = this.sanitizer.bypassSecurityTrustHtml(response.wert);
      }, err => {
        throw err?.error?.message || err?.error?.Message || this.languageService.translate('serverError');
      });
    });
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageObservable) {
      this.selectedLanguageObservable.unsubscribe();
    }
  }
}
