import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UrlService } from '../services/url.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

    constructor(private urlService: UrlService, private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const returnUrl = state.url.split('?')[0];
        return this.can(returnUrl);
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        let returnUrl = this.router.getCurrentNavigation().extractedUrl.toString().split('?')[0];
        return this.can(returnUrl);
    }

    can(returnUrl: string) {
        if (this.authService.isLoggedIn()) {
            return true;
        }

        this.urlService.naviToMergeQueryParams('extern/login',{ returnUrl: returnUrl } );
        return false;
    }
}