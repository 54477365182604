<dx-popup
  #changePasswordPopup
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  title="{{ 'changePassword' | translate }}"
  width="auto"
  maxWidth="500"
  minWidth="320"
  height="auto"
>
  <div *dxTemplate="let data of 'content'">
    <dx-form #passwordForm class="password-edit-form" [formData]="passwordFormData">
      <dxi-item dataField="altPasswort" [editorOptions]="{ mode: 'password' }" [label]="{ text: 'password' | translate }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        dataField="passwort"
        [editorOptions]="{
          valueChangeEvent: 'keyup',
          mode: 'password',
          maxLength: configService.passwordPolicy.maxLaenge,
          onValueChanged: onPasswordChanged()
        }"
        [label]="{ text: 'newPassword' | translate }"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          [pattern]="configService.passwordPolicy.minZiffern"
          [message]="configService.passwordPolicy.minZiffernMessage"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          [pattern]="configService.passwordPolicy.minKleinBuchstaben"
          [message]="configService.passwordPolicy.minKleinBuchstabenMessage"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          [pattern]="configService.passwordPolicy.minGrossBuchstaben"
          [message]="configService.passwordPolicy.minGrossBuchstabenMessage"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          [pattern]="configService.passwordPolicy.minSonderzeichen"
          [message]="configService.passwordPolicy.minSonderzeichenMessage"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          [pattern]="configService.passwordPolicy.minLaenge"
          [message]="configService.passwordPolicy.minLaengeMessage"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="passwortConfirm" [editorOptions]="{ mode: 'password' }" [label]="{ text: 'repeatPassword' | translate }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule
          type="compare"
          [comparisonTarget]="passwordComparison"
          message="{{ 'wrongConfirmPassword' | translate }}"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item cssClass="psw-richtlinien-container" itemType="group" colCount="3" [template]="'pswRichtlinieTemplate'"> </dxi-item>

      <div *dxTemplate="let data of 'pswRichtlinieTemplate'">
        <dxi-item colSpan="2">
          <span class="psw-richtlinien" [innerHTML]="configService.passwordPolicy.text"></span>
        </dxi-item>

        <dxi-item
          cssClass="benutzername"
          dataField="benutzername"
          colSpan="1"
          [label]="{ text: 'user' }"
          [editorOptions]="{ valueChangeEvent: 'keyup', maxLength: 50, focusStateEnabled: false }"
        >
        </dxi-item>
      </div>
    </dx-form>

    <div class="msg-text-container">
      <span id="psw_msg" class="msg-text color-error">{{ msg }}</span>
    </div>
  </div>

  <dxi-toolbar-item
    widget="dxButton"
    location="before"
    toolbar="bottom"
    [options]="{ text: 'cancel' | translate, type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }"
  >
  </dxi-toolbar-item>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{ text: 'save' | translate, type: 'default', stylingMode: 'contained', icon: 'save', onClick: onClickSavePassword }"
  >
  </dxi-toolbar-item>
</dx-popup>
