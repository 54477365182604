import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './share/guards/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: 'extern', pathMatch: 'full' },
  {
    path: 'extern', loadChildren: () => import('./citizen-extern/citizen-extern.module').then(m => m.CitizenExternModule)
  },
  {
    path: 'intern', loadChildren: () => import('./citizen-intern/citizen-intern.module').then(m => m.CitizenInternModule), canActivate: [AuthGuard], canLoad: [AuthGuard]
  },
  {
    path: '**', redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
