import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParameterCodec } from "@angular/common/http";
import { StorageService } from './storage.service';

@Injectable()
export class UrlService {

  last_externSearchQueryParams: string;
  private _externSearchQueryParams: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_externSearchQueryParams(): Observable<any> {
    return this._externSearchQueryParams.asObservable();
  }

  last_externSelectedIdsQueryParams: string;
  private _externSelectedIdsQueryParams: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public get get_externSelectedIdsQueryParams(): Observable<number[]> {
    return this._externSelectedIdsQueryParams.asObservable();
  }

  last_externSelectedCareTypeIdsQueryParams: string;
  private _externSelectedCareTypeIdsQueryParams: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public get get_externSelectedCareTypeIdsQueryParams(): Observable<number[]> {
    return this._externSelectedCareTypeIdsQueryParams.asObservable();
  }

  constructor(private route: ActivatedRoute, private router: Router, private storageService: StorageService) {
    this.route.queryParamMap.subscribe(paras => {

      const raw_search = paras.get('s');
      if (raw_search !== null && raw_search !== undefined && raw_search.length > 0) {
        const search = decodeURIComponent(atob(raw_search));
        if (raw_search !== this.last_externSearchQueryParams) {
          this._externSearchQueryParams.next(JSON.parse(search));
          this.last_externSearchQueryParams = raw_search;
        }
      }
      else {
        this._externSearchQueryParams.next(null);
      }

      //-----

      const raw_ids = paras.get('i');
      if (raw_ids !== null && raw_ids !== undefined && raw_ids.length > 0) {
        const idStringArray = raw_ids.split(',');
        const idArray = idStringArray.map(x => +x);

        if (raw_ids !== this.last_externSelectedIdsQueryParams) {
          this._externSelectedIdsQueryParams.next(idArray);
          this.last_externSelectedIdsQueryParams = raw_search;
        }
      }
      else {
        this._externSelectedIdsQueryParams.next([]);
      }

      //-----
      const raw_careTypeId = paras.get('t');
      if (raw_careTypeId !== null && raw_careTypeId !== undefined && raw_careTypeId.length > 0) {
        const idStringArray = raw_careTypeId.split(',');
        const idArray = idStringArray.map(x => +x);

        if (raw_careTypeId !== this.last_externSelectedIdsQueryParams) {
          this._externSelectedCareTypeIdsQueryParams.next(idArray);
          this.last_externSelectedCareTypeIdsQueryParams = raw_search;
        }
      }
      else {
        this._externSelectedCareTypeIdsQueryParams.next([]);
      }
    });
  }

  naviToMergeQueryParams(path: string, queryParams: any): Promise<boolean> {
    return this.router.navigate([path], {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  naviToPreserveQueryParams(path: string, queryParams: any = null): Promise<boolean> {
    return this.router.navigate([path], { queryParamsHandling: 'preserve', queryParams: queryParams });
  }

  naviTo(path: string, queryParams: any): Promise<boolean> {
    return this.router.navigate([path], { queryParams: queryParams });
  }

  refreshQueryParams(queryParams: any): Promise<boolean> {
    if (queryParams.i != undefined && queryParams.i != null) {
      this.storageService.saveToLocale("selectedIds", queryParams.i);
    }

    return this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }
}

export class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
