import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { ConfigService } from '../../services/config.service';
import { LanguageService } from '../../services/language.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit, OnDestroy {
  @ViewChild('calcForm', { static: false }) calcForm: DxFormComponent;

  formData: any = {};
  hinweisText: string;
  inProcess: boolean = false;
  betrag: number = 0;
  msg: string;
  istBerechnet: boolean = false;
  istEKAbhaengig: boolean = true;
  istZaehl_KZ: boolean = false;
  istErmaessigungsTypRelevant: boolean = true;
  einkommensArt: number = 0;
  einkommenText: string = '';
  kind_format: string = "#'.' " + this.languageService.translate('positionOfKid_2');
  betreuungsUmfangDataSource: any;
  selectedLanguageObservable: Subscription;
  betreuungsArtDataSource: any[] = this.configService.getKatalogMitKeineAntragstellung('BetreuungsArtKatalog');

  constructor(public configService: ConfigService, private languageService: LanguageService, private apiService: ApiService, public router: Router) {
    this.onCalcClick = this.onCalcClick.bind(this);
    this.onClickReset = this.onClickReset.bind(this);
    this.onMaxEkValueChanged = this.onMaxEkValueChanged.bind(this);
    this.onBetreuungsArtValueChanged = this.onBetreuungsArtValueChanged.bind(this);

    this.selectedLanguageObservable = languageService.get_selectedLanguageObservable.subscribe((lang) => {
      this.setEinkommensText();
    });
  }

  ngOnInit(): void {
    this.hinweisText = this.configService.getGlobalSetting('brBemerkung' + this.languageService.getLanguage().toUpperCase());
    this.istEKAbhaengig = this.configService.getGlobalSetting('brEK_Abhaengig') === '1' ? true : false;
    this.istZaehl_KZ = this.configService.getGlobalSetting('brErmaessigungsTyp') === '1' ? true : false;
    this.einkommensArt = parseInt(this.configService.getGlobalSetting('brEinkommensArt'));
    this.setEinkommensText();
    const _berArt = parseInt(this.configService.getGlobalSetting('brWerteTyp'));
    if (_berArt === 2) {
      this.istErmaessigungsTypRelevant = false;
    }

    this.formData.istMaxEinkommen = false;
    this.formData.alleinerziehend = false;
    this.formData.einkommen = undefined;
    this.formData.anzahlKinder = 1;
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageObservable) {
      this.selectedLanguageObservable.unsubscribe();
    }
  }

  onCalcClick(e) {
    const validate = this.calcForm.instance.validate();
    if (validate.isValid) {
      this.inProcess = true;

      this.apiService.post(RouteKeys.Calculator.get_beitrag, this.formData).subscribe({
        next: (response) => {
          const result: any = response;
          if (result !== null && result !== undefined && result.beitrag !== null && result.beitrag !== undefined) {
            this.betrag = result.beitrag;
            this.istBerechnet = true;
          }
          this.inProcess = false;
        },
        error: (err) => {
          let _err;

          if (err.error || err.Error) {
            const ___err = err.error || err.Error;
            if (___err.message || ___err.Message) {
              _err = ___err.message || ___err.Message;
            } else {
              _err = ___err;
            }
          } else if (err.message || err.Message) {
            _err = err.message || err.Message;
          } else {
            _err = err;
          }
          this.inProcess = false;
          throw _err || this.languageService.translate('serverError');
        },
      });
    } else {
      this.istBerechnet = false;
    }
  }

  onClickReset() {
    this.calcForm.instance.resetValues();
    this.formData.istMaxEinkommen = false;
    this.formData.alleinerziehend = false;
    this.istBerechnet = false;
    this.betrag = 0;
    this.formData.einkommen = 0.0;
    this.calcForm.instance.repaint();
  }

  onMaxEkValueChanged(e) {
    const ek = this.calcForm.instance.getEditor('einkommen');
    if (e.value === true) {
      ek.reset();
    } else {
      this.formData.einkommen = 0.0;
      ek.repaint();
    }
  }

  setEinkommensText() {
    switch (this.einkommensArt) {
      case 0: {
        this.einkommenText = this.languageService.translate('annualGrossIncome');
        break;
      }
      case 1: {
        this.einkommenText = this.languageService.translate('annualNetIncome');
        break;
      }
      case 2: {
        this.einkommenText = this.languageService.translate('mtlGrossIncome');
        break;
      }
      case 3: {
        this.einkommenText = this.languageService.translate('mtlNetIncome');
        break;
      }
      default: {
        this.einkommenText = this.languageService.translate('annualGrossIncome');
      }
    }
  }

  onBetreuungsArtValueChanged(e) {
    this.betreuungsUmfangDataSource = this.configService.getKatalog('BetreuungsUmfangKatalog').filter((x) => x.beziehungen.includes(e.value));
    if (!this.betreuungsUmfangDataSource.find((x) => x.id === this.formData.betreuungsUmfang)) {
      this.formData.betreuungsUmfang = undefined;
    }
  }
}
