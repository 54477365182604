import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
                this.authService.logout();
            }

            return throwError(() => {
                if (err.status < 500) {
                    return {
                        status: err.status,
                        message: err.message
                    };
                }
                if (err.status === 600) {
                    return {
                        status: err.status,
                        message: err.error
                    }
                }
                else {
                    return {
                        status: err.status,
                        message: err.error?.message || err.error?.Message,
                        stack: err.error?.stack || err.error?.Stack
                    };
                }
            });

        }));
    }
}


export const errorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
};