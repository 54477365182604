<dx-popup #detailPopup [fullScreen]="false" [showTitle]="true" [dragEnabled]="false" [hideOnOutsideClick]="true"
    [showCloseButton]="true" [width]="1200" [height]="720" (onShown)="onShown($event)" (onHidden)="onHidden($event)">

    <dxi-toolbar-item template="customTitleTemplate" location="before">
        <div *dxTemplate="let data of 'customTitleTemplate'" class="popup-header">
            <div *ngIf="itemToShowDetails !== undefined && itemToShowDetails !== null" class="popup-header-title">
                {{itemToShowDetails.einrichtungsName}}</div>
        </div>
    </dxi-toolbar-item>

    <dxi-toolbar-item *ngIf="!withoutBtns && itemToShowDetails !== undefined && itemToShowDetails.isSelectable" widget="dxButton" location="after" toolbar="bottom" [options]="{
        stylingMode: 'contained',
        text: isSelected ? ('deselect' | translate) : 'select' | translate,
        type: 'default',
        icon: isSelected ? 'trash' : 'selectall',
        visible: !isPreview,
        onClick: onSelectClick
    }">
    </dxi-toolbar-item>

    <dxi-toolbar-item *ngIf="!withoutBtns" widget="dxButton" location="after" toolbar="bottom" [options]="{
        stylingMode: 'contained',
        text: ('close' | translate),
        type: 'default',
        icon: 'remove',
        onClick: onAbbruchClick
    }">
    </dxi-toolbar-item>

    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
            <div class="popup-container-content">

                <div class="popup-container-content-info">

                    <div class="popup-container-content-info-col1">
                        <b><span [innerHtml]="'address' | translate"></span>:</b><br>
                        {{itemToShowDetails.strasse}} {{itemToShowDetails.hnr}}{{itemToShowDetails.hnrzusatz}}<br>
                        {{itemToShowDetails.plz}} {{itemToShowDetails.ort}}<br><br>

                        <b><span [innerHtml]="'holder' | translate"></span>:</b><br>
                        {{itemToShowDetails.traegerName}}<br><br>

                        <b><span [innerHtml]="'distance' | translate"></span>:</b><br> {{itemToShowDetails.distance}} km
                    </div>

                    <div class="popup-container-content-info-col2">
                        <b><span [innerHtml]="'openingHours' | translate"></span>:</b><br>

                        <div class="content-info-col2-uhrzeit">
                            <div class="content-info-col2-uhr1">
                                <span *ngFor="let data of wochentage; index as iTag">
                                    <span class="content-info-col2-container">
                                        <span class="content-info-col2-content"
                                            [innerHtml]="data.value | translate"></span>
                                        <span class="content-info-col2-content"
                                            [innerHtml]="itemToShowDetails.oeffnungszeiten[iTag].beginn"></span>
                                        <span class="content-info-col2-content-separator">-</span>
                                        <span class="content-info-col2-content"
                                            [innerHtml]="itemToShowDetails.oeffnungszeiten[iTag].ende"></span>
                                        <span class="content-info-col2-content"
                                            [innerHtml]="'clockAM' | translate"></span>
                                    </span>

                                    <span
                                        *ngIf="itemToShowDetails.oeffnungszeiten[iTag].beginn2!==null || itemToShowDetails.oeffnungszeiten[iTag].ende2!==null"
                                        class="content-info-col2-container">
                                        <span class="content-info-col2-content" [innerHtml]=" "></span>
                                        <span class="content-info-col2-content"
                                            [innerHtml]="itemToShowDetails.oeffnungszeiten[iTag].beginn2"></span>
                                        <span class="content-info-col2-content-separator">-</span>
                                        <span class="content-info-col2-content"
                                            [innerHtml]="itemToShowDetails.oeffnungszeiten[iTag].ende2"></span>
                                        <span class="content-info-col2-content"
                                            [innerHtml]="'clockAM' | translate"></span>
                                    </span>

                                </span>
                            </div>
                        </div><br>

                        <b><span [innerHtml]="'totalCapacity' | translate"></span>:</b><br>
                        {{itemToShowDetails.platzanzahl}} {{'seats' | translate}}<br><br>
                    </div>

                    <div class="popup-container-content-info-col3">
                        <b><span
                                [innerHtml]="'contactPerson' | translate"></span>:</b><br>{{itemToShowDetails.ansprechpartner}}<br><br>
                        <b><span [innerHtml]="'careTypes' | translate"></span>:</b><br>
                        <span *ngFor="let arten of itemToShowDetails.betreuungsarten">
                            - {{arten.value}}<br>
                        </span><br>

                        <a *ngIf="itemToShowDetails.web!==null && itemToShowDetails.web.length > 0"
                            href={{itemToShowDetails.web}} target="_blank"><i class="dx-icon-home"></i> {{'homepage' |
                            translate}}</a><br><br>
                    </div>

                    <div class="popup-container-content-info-col4">
                        <b><span [innerHtml]="'email' | translate"></span>:</b><br> {{itemToShowDetails.email}}<br><br>
                        <b><span [innerHtml]="'phone1' | translate"></span>:</b><br>
                        {{itemToShowDetails.telefon1}}<br><br>
                        <b><span [innerHtml]="'phone2' | translate"></span>:</b><br>
                        {{itemToShowDetails.telefon2}}<br><br>
                        <b><span [innerHtml]="'fax' | translate"></span>:</b><br> {{itemToShowDetails.fax}}
                    </div>
                </div>

                <div class="popup-container-content-text"
                    *ngIf="itemToShowDetails.beschreibung !== '' && itemToShowDetails.beschreibung !== null">
                    <span>
                        <b><span [innerHtml]="'description' | translate"></span>:</b>
                        <div [innerHtml]="beschreibung"></div>
                    </span>
                </div>

                <div class="popup-container-content-bilder">
                    <div class="popup-container-content-bilder-col11" *ngIf="showGallery">
                        <b><span class="popup-container-content-bilder-header"
                                [innerHtml]="'photoGallery' | translate"></span></b>
                        <dx-gallery #gallery id="gallery" [dataSource]="pictures" width="100%" [height]="453"
                            [loop]="true" [showNavButtons]="true">
                        </dx-gallery>
                    </div>
                    <div class="popup-container-content-bilder-col12">
                        <b><span class="popup-container-content-bilder-header"
                                [innerHtml]="'location' | translate"></span></b>
                        <app-map-osm *ngIf="isShowMap" id="map" #map [centerImage]="'assets/marker-ownLocation.png'"
                            [centerTitle]="itemToShowDetails.einrichtungsName"
                            [centerPopupDetails]="itemToShowDetails.einrichtungsName"
                            [centerLat]="itemToShowDetails.lat" [centerLng]="itemToShowDetails.lon"
                            [isDraggable]="false"></app-map-osm>
                    </div>
                </div>
            </div>
        </dx-scroll-view>
    </div>
</dx-popup>