import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from '../services/language.service';

@Pipe({
    name: 'translate',
    pure: false
})
export class LanguagePipe implements PipeTransform {

    constructor(private languageService: LanguageService, protected sanitizer: DomSanitizer) { }

    transform(text: string, params: string[] = []): string  {
        return this.sanitizer.sanitize(SecurityContext.NONE, this.languageService.translate(text, params));
    }
}

